<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleCancel"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div class="table">
        <div class="tr">
          <div class="td">基本工资</div>
          <div class="td">{{ record.basic_salary }}</div>
        </div>
        <div class="tr" v-for="item in record.fixed_subsidy_list">
          <div class="td">{{ item.name }}</div>
          <div class="td">{{ item.amount }}</div>
        </div>
        <div class="tr">
          <div class="td">提成工资</div>
          <div class="td">{{ record.commission }}</div>
        </div>
      </div>

      <template v-if="!isEmpty(record.additional_list)">
        <a-divider orientation="left">额外费用</a-divider>
        <div class="table">
          <div class="tr" v-for="item in record.additional_list">
            <div class="td">{{ item.additional_name }}</div>
            <div class="td">
              <span style="color: red" v-if="item.additional_type==1">-{{ item.amount }}</span>
              <span style="color: #04be02" v-else>+{{ item.amount }}</span>
              <!--            {{ item.amount }}-->
            </div>
          </div>
        </div>
      </template>


      <div
          v-if="record.status>=3"
          style="font-size: 15px;font-weight: 500;margin-top: 30px;text-align: right">
        发放时间：{{ record.grant_time }}<br>
      </div>

      <div
          v-if="record.status>=4"
          style="font-size: 15px;font-weight: 500;margin-top: 30px;text-align: right">
        签收时间：{{ record.sign_time }}<br>
      </div>

      <div style="font-size: 15px;font-weight: 500;margin-top: 30px;text-align: right">
        实发工资：￥{{ record.net_salary }}<br>
      </div>


    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/personnel/additional'
import {UploadFile} from '@/components'
import _ from "lodash";
import {isEmpty} from "@/utils/util";

export default {
  props: ['payroll_id'],
  components: {
    UploadFile
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: ""
    }
  },
  methods: {
    isEmpty,

    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '新增费用'
      this.visible = true
    },
    edit(record) {
      this.mode = "edit"
      this.title = '查看明细'
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['additional_type', 'additional_name', 'amount'])
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
      this.$emit('handleSubmit')

    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        values.payroll_id = this.payroll_id
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({paId: this.record['pa_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }
  }
}
</script>

<style lang="less" scoped>
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    height: 40px;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      height: 40px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;

      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
