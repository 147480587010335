<template>
  <a-card :bordered="false">
    <div class="card-title">选择年份/月份</div>
    <div class="body">
      <div class="month-menus">
        <div class="item">
          <a-date-picker
              format="YYYY年"
              mode="year"
              :value="yearValue"
              :open="isopen"
              placeholder="选择年份"
              @change="onChange"
              @openChange="openChange"
              @panelChange="panelChange"
          />
        </div>
        <template
            v-if="!isEmpty(monthList)"
        >
          <div
              :class="['item',currDate==item.month_time?'curr':'']"
              v-for="item in monthList"
              @click="handleSetCurr(item)"
          >
            {{ item.month_str }}
          </div>
        </template>
        <template
            v-else
        >
          <div class="item">未找到有关信息</div>
        </template>
      </div>
    </div>
  </a-card>
</template>
<script>
import moment from "moment/moment";
import * as Api from '@/api/statistics/customerBill'
import {isEmpty} from "@/utils/util";

export default {
  data() {
    return {
      yearValue: moment(),
      isopen: false,
      monthList: [],
      currDate: ''
    }
  },
  created() {
    this.getYearList()
  },
  methods: {
    isEmpty,
    moment,

    async getYearList() {
      const {data: {list}} = await Api.yearMonth(this.yearValue.format('YYYY'))
      if (list.length > 0) {
        this.handleSetCurr(list[0])
      }
      this.monthList = list
    },
    handleSetCurr(currDate) {
      this.currDate = currDate.month_time
      this.$emit('handleChange', currDate)
    },
    onChange() {
      this.yearValue = null
    },
    openChange(isopen) {
      this.isopen = !!isopen
    },
    panelChange(value) {
      this.yearValue = value
      this.isopen = false
      this.getYearList()
    },
  }
}
</script>
<style scoped lang="less">
.body {
  display: flex;
  border: 1px solid #e8e8e8;

  .month-menus {
    width: 260px;

    .item {
      cursor: pointer;
      padding: 10px;
      text-align: center;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: none
      }
    }

    .curr {
      background: #378DDB;
      color: #fff;
    }
  }
}
</style>
