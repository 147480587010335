<template>
  <a-modal
      :title="title"
      :width="1380"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <a-button v-action:export_log type="primary" @click="handleExcel" :loading="excelLoading">导出当前数据</a-button>
        <a-button @click="handleRefresh" style="margin-left: 10px">刷新列表</a-button>
        <SearchForm
            ref="SearchForm"
            :addressList="address_list"
            :goodsList="goods_list"
            @handleSubmit="handleSeachSubmit"
        />
      </div>

      <div style="border: 1px solid #e8e8e8;padding: 10px;margin-bottom: 10px">
        <b>合计：</b>
        <div class="tj">
          <div>提货数量(吨)：{{ totalData.zhsl }}</div>
          <div>交货数量(吨)：{{ totalData.xhsl }}</div>
          <div>损耗量：{{ totalData.loss }}</div>
          <div>应收客户运输费用（元）：{{ totalData.total_amount }}</div>
        </div>
      </div>

      <s-table
          ref="table"
          rowKey="bill_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{x:1160,y:500}"
      >
        <div slot="price" slot-scope="text, item">
          <a @click="editOvPrice(item)"><span style="color: red">¥</span>{{ text }}</a>
        </div>
        <div slot="total_amount" slot-scope="text, item">
          <span style="color: red">¥</span>{{ text }}
        </div>
        <span slot="address" slot-scope="text, record">
          <div class="descriptionTxt">{{ text }}</div>
        </span>

<!--        <div class="actions" slot="action" slot-scope="text, item">-->
<!--          <a v-action:edit @click="handleEdit(item)">编辑</a>-->
<!--          <a-popconfirm-->
<!--              v-action:delete-->
<!--              title="确认删除当前记录？"-->
<!--              ok-text="确定"-->
<!--              cancel-text="取消"-->
<!--              @confirm="handleDelete(item)"-->
<!--          >-->
<!--            <a href="#">删除</a>-->
<!--          </a-popconfirm>-->
<!--        </div>-->
      </s-table>
    </a-spin>
    <EditOrderVehiclePrice
        ref="EditOrderVehiclePriceModel"
        @handleSubmit="handleRefresh"
    />
  </a-modal>
</template>
<script>
import * as Api from '@/api/statistics/customerBill'
import * as addressApi from '@/api/data/address'
import * as goodsApi from "@/api/data/goodsName";

import {STable} from "@/components";
import EditOrderVehiclePrice from './EditOrderVehiclePrice.vue'
import SearchForm from './SearchForm.vue'

export default {
  components: {
    STable,
    EditOrderVehiclePrice,
    SearchForm
  },
  data() {
    return {
      title: '',
      queryParam: {
        customer_id: '',
        month_time: '',
      },
      visible: false,
      loading: false,
      isLoading: false,

      excelLoading: false,
      columns: [
        {
          title: '完成时间',
          dataIndex: 'complete_time',
          width: 100
        },
        {
          title: '运输货物名称',
          dataIndex: 'yshwmc',
          align: 'center',
          width: 120
        },
        {
          title: '提货数量(吨)',
          dataIndex: 'zhsl',
          align: 'center',
        },
        {
          title: '交货数量(吨)',
          dataIndex: 'xhsl',
          align: 'center',
        },
        {
          title: '运输货物损耗量(吨)',
          dataIndex: 'loss',
          align: 'center',
        },
        {
          title: '发车起点',
          dataIndex: 'start_address',
          align: 'center',
          width: 200
        },
        {
          title: '到达终点',
          dataIndex: 'end_address',
          align: 'center',
          width: 200
        },
        {
          title: '运输单价',
          dataIndex: 'price',
          align: 'center',
          scopedSlots: {customRender: 'price'}
        },
        {
          title: '车号',
          dataIndex: 'vehicle.vehicle_name',
          align: 'center',
          width: 100
          // scopedSlots: {customRender: 'x_commission'}
        },
        {
          title: '应收客户运输费用（元）',
          dataIndex: 'total_amount',
          align: 'center',
          scopedSlots: {customRender: 'total_amount'},
          width: 200
        },

      ],
      totalData: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        Api.getTotalData({...param, ...this.queryParam})
            .then(response => {
              this.totalData = response.data
            })
        return Api.costList({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },
      address_list: [],
      goods_list: [],
    }
  },
  methods: {

    handleExcel() {
      const searchForm = this.$refs.SearchForm.getValues()
      this.excelLoading = true
      Api.costList({...this.queryParam, ...searchForm, excel: 1})
          .then(({data: {list}}) => {
                this.downloadFile(list)
                this.excelLoading = false
              }
          )
    },
    downloadFile(url) {
      try {
        let elementIftrame = document.createElement("iframe");
        elementIftrame.src = url;
        elementIftrame.style.display = "none";
        document.body.appendChild(elementIftrame);
      } catch (error) {
        console.log(error);
      }
    },


    open({customer_name,}) {
      this.title = customer_name + ' - 费用明细'
      this.visible = true
      this.getAddress()
      this.getGoods()
    },

    /**
     * 获取地址记录
     */
    async getAddress() {
      this.loading = true
      const {data: {list}} = await addressApi.getSelectList()
      this.address_list = list
      this.loading = false
    },

    /**
     * 获取货物品名
     */
    async getGoods() {
      this.loading = true
      const {data: {list}} = await goodsApi.getSelectList()
      this.goods_list = list
      this.loading = false
    },

    cancel() {
      this.visible = false
      this.queryParam = {}
      this.$refs.SearchForm.handleReset()
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    // handleDelete(item) {
    //   Api.deleted({vrId: item['vr_id']})
    //       .then((result) => {
    //         this.$message.success(result.message, 1.5)
    //         this.handleRefresh()
    //       })
    // },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },
    /**
     * 编辑订单单价
     */
    editOvPrice(item) {
      this.$refs.EditOrderVehiclePriceModel.open(item)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">
.descriptionTxt {
  width: 220px;
  word-wrap: break-word;
  padding: 2px !important;
  white-space: pre-wrap;
}

.tj {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 25%;
  }
}
</style>
